/**
 * The breakpoint display component is used to definet component layouts per breakpoint in a given display.  This can be used at any
 *
 * @module views/components/BreakpointDisplay
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

/**
 * Represents a BreakpointDisplay component
 *
 * @method
 * @param {BreakpointDisplayProps} props - React properties passed from composition
 * @returns BreakpointDisplay
 */
export const BreakpointDisplay = function( props ){
  const { inflection } = useDeviceInflection();

  return (
    <div className='BreakpointDisplay'>
      {
        props[inflection.TYPE].moduleRefs?.map( ( i, index ) => {
          const module = props.modules?.find( el => el.id === i );
          if( module ){
            return (
              <AsyncComponent
                key={ `${inflection.TYPE}:${module.id}:${index}` }
                { ...module }
              />
            );
          }
        } )
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef BreadcrumbsProps
 * @type {object}
 * @property {string} example - refactor or delete
 */
export const propTypes =  {
  desktop: PropTypes.object,
  mobile: PropTypes.object
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} example='hello world' - The default refactor or delete
 */
export const defaultProps =  {
  desktop:{},
  mobile:{}
};

BreakpointDisplay.propTypes = propTypes;
BreakpointDisplay.defaultProps = defaultProps;

export default BreakpointDisplay;
